

body
{
	background-color: #fff;
	font-size: 100%;
}

.disclamer ol li {
	text-align: justify !important;
	font-size: 20px;
}
.terms {
	max-height:  500px;
	display: block;
	overflow-y: auto;
}
.row{
	margin-right: 0px !important;
	margin-left: 0px !important;
}
.no-padding {
	padding: 0 !important;
}
.header {
	padding: 0 !important;
}
.body {
  background-color: #fff;	
  min-height: calc(100vh - 132px);

}
#root {
	min-height: 100%;
}
.App{
	min-height: 100%;
	display: block;
}
.home {
	background-color: #FFF;
	min-height: calc(100vh - 132px);
	display:block;
	overflow-y: auto;
	/*padding-bottom: 100px;*/

}
.main_content {
	/*height: 100%;
	overflow-y: auto;*/
	padding: 0;
	/*padding-bottom: 100px;*/
	display: block;
}
.footer {
	/*position: ;*/
    bottom: 0;
    margin:0 !important;
    padding: 0 !important;
    width: 100%;
    display:block;
    background-color: #f5f5f5;
  
}
.sidebar {
	border-right: 2px solid #ccc;
	padding-right: 0;
	padding-left: 0;
}

.breadcrumb {
	margin-top: 5px;
}
.navbar-brand {
	font-weight: bold;
	font-size: 24px;
	color: #ddd !important;
}

a.nav-link{
	font-size: 20px !important;
	color: white
}
div.login-header {
	text-align: right;
	padding-top: 10px;
	padding-bottom: 10px;
}
img.logo{
	width: 100px;
	margin:30px 0px 0px 50px; 
}
/*menu Button*/
 .menu_button label {
 	margin-left: 5px;
 	font-size:125% !important;
 }
 .menu_button label:hover {
 	cursor: pointer;	
 }

.menu_button:hover {
	background-color: #777 !important;
}
.menu_button.active{
 	border-color: #aaa;
	background-color: #555 !important;
 }
 /**sidebars*/
.sidebar_button label:hover{
  cursor: pointer;
 }
.sidebar_button.active{
  border-color: #aaa;
  background-color: #999 !important;
}

 /*Forms*/
 .form_buttons button {
 	margin-left: 10px;
 }
 .home {
 	width:100%;
 	display: block;
 }

 .user_icon {
 	color:#FFF;
 }

 .user_icon:hover {
 	color:#AAA;
 }
 .spinner {
 	z-index: 1000;
 	position: absolute;
 	width: 100%;
 	height: 100%;
 	background-color: #CCC;
 	opacity: 0.3;
 }

 .spinner img {
 	margin-top: 200px;
 	width: 300px;
 	margin-left: auto;
 	margin-right: auto;
 	display: block;
 }
 .spinner h3{
 	text-align: center;
 }

 h1 small{
 	font-size: 35%;
 }
 .about_page {
 	font-size: 150%;
 }
 .about_page h4{
 	font-size: 125%;
 }
 .about_page p{
 	text-align: justify;
 }
 .carousel-caption p{
 	font-size: 125%;
 	font-weight: bold;
 	text-shadow: 1px 1px #333;

 }

 .btn{
 	padding: 10px 10px;
 	font-size: 22px;
 	font-weight: 150%;
 }
 .btn-danger{
 	font-size: 18px;
 	padding: 5px 10px;
 }
 .product_list{
 	max-height: 300px;
 	overflow-x: auto;
 	display: block;
 }
.resumen td:last-child{
	font-weight: bold;
	font-size: 20px !important;
	padding: 10px;
}
.acept_invoice {
	height: 100%;
	display: block;
	font-size: 22px;
	font-weight: bold !important;
	padding: 10px 10px !important;
}

.balance_chart {
	width: 75%;
}
.c3-legend-item {
	font-size: 20px !important;

}
