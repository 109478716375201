@media only screen and  (max-width: 1280px) {
  a.nav-link {
    font-size: 17px !important;
  }
  a.nav-link label {
    padding: 0px !important;
    margin: 1px !important;
  }
  div.login-header{
    padding: 0 5px;
  }
  button.btn-lg {
    padding: 5px 8px;
  }
  .col-md-10 {
    padding:0;
  }
  .col-md-12 {
    padding:0;
  }
  /*Sidebar*/
a.sidebar_button {
  border-bottom: solid 1px #999 !important;
  padding: 10px 10px
}
.sidebar_button avg {
  width: 10px;
  display: block;
}

 .sidebar_button label{
  font-size: 20px;
  margin-left: 5px;   
 }
 
  tbody.list{
      font-size: 100% !important;
  }
  th{
    padding: 6px !important;
    font-size: 12px;
  }
  button label, a label{
    font-size: 18px !important;
  }
  td{
    font-size: 17px !important;
  }

 .btn{
  padding: 6px 6px !important;
  font-size: 22px !important;
  font-weight: 150%;
 }
}

@media only screen and  (max-width: 1368px) {
  a.nav-link {
  	font-size: 17px !important;
  }
  div.login-header{
  	padding: 0 5px;
  }
  button.btn-lg {
  	padding: 5px 8px;
  }
  .col-md-10 {
  	padding:0;
  }
  .col-md-12 {
  	padding:0;
  }
  /*Sidebar*/
a.sidebar_button {
  border-bottom: solid 1px #999 !important;
  padding: 10px 10px
}
.sidebar_button avg {
  width: 10px;
  display: block;
}

 .sidebar_button label{
  font-size: 20px;
  margin-left: 5px;   
 }
 
  tbody.list{
      font-size: 100% !important;
  }
  th{
    padding: 6px !important;
    font-size: 12px;
  }
  button label, a label{
    font-size: 18px !important;
  }
  td{
    font-size: 17px !important;
  }
}

@media only screen and  (min-width: 1369px) {
  input, select, textarea{
    font-size: 120% !important;
  }
  a.nav-link {
    font-size: 20px !important;
  }

  label {
    font-size: 18px;
  }


  tbody.list  {
    font-size: 100% !important;
  }
  tbody.list td{
    padding: 6px !important;
  }  

  .sidebar_button label{
    font-size: 25px;
  }
  button label, a label{
    font-size: 120%;
  }
  td{
    font-size: 20px !important;
  }
}