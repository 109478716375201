  	button,
button:active,
button:focus, 
button:hover,
.btn,
.btn:active, 
.btn:focus, 
.btn:hover{   
    outline:none !important;
}

button label, a label {
	cursor: pointer;
}
input, select, textarea {
	border: 2px solid #aaa !important;
}
span.error{
	color: red;
	font-weight: bold;
}
.clear {
	clear: both;
}
.field_error input {
	border-color: #dc3545;
}
.field_error small {
	color: #dc3545;
	font-weight: bold;
}
.checkbox_label{
	padding-right: 15px;
}
legend{
	font-size: 16px;
}
.delete_phone {
	margin-top: 31px !important;
}
ul.phone_list {
	margin:0;
	padding:0;
}

li.phone_list_item{
	list-style: none;
	margin:0;
	padding:0;
}
li.group_list_item{
	list-style: none;
	float:left;
	margin-right: 20px;
	font-size: 20px;
}
.hidde{
	display: none;
}
.login_form {
	width: 100%;
}

tr.product_item {
	font-size: 150%;	
}
tr.product_item:hover {
	cursor: pointer;
}
tr.product_item:active {
	background-color: #CCC !important;
}
.td-cant {
	width: 10% !important;
}
.td-desc {
	width: 40%  !important;
}
.td-prec {
	width: 27%  !important;
}
.td-total {
	width: 28%  !important;
}
.input-td input{
	max-width: 120px !important;
}
.input-td-cant input{
	max-width: 50px !important;
}
span.moneda{
	font-size: 60%;
}
div.moneda {	
	padding: 4px !important;
}
table.select_table tbody tr:hover{
	background-color: #FFF !important;
}
.modal-lg {
	max-width: 1000px;
}

tbody.list tr:hover{
	background: #ccc !important;
	cursor: pointer;
	font-weight: bold;
}
tr.product_item:hover{
	background: #ccc !important;
	cursor: pointer;
	font-weight: bold;
}
tr.product_select,  tr.product_select:hover{
	background-color:#666 !important;
	font-weight: bold;
	color: #FFF;
}
tr.invoice_line {
	font-size: 125%;	
}


/*
tr.invoice_line td input{
	width:130px !important;
}*/
.info {
	font-size: 130%;
}
.single_checkbox input[type=checkbox]{
	zoom: 2;
}
.single_checkbox label{
	font-size: 18px;
}
button.float-right {
	margin-left: 10px;
}
.invoice_button {
	border-bottom: 2px solid #FFF !important;
	width: 100%;
}
h3 small {
	font-size:50%;
}

